import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  ReactiveFormsModule,
  FormsModule,
} from '@angular/forms';
import { RouterLink } from '@angular/router';
import { CommonModule } from '@angular/common';
import { AuthService } from '@ea/auth';
import { LocalStorageService, ToastService } from '@ea/services';

@Component({
  selector: 'ea-reset-email-page',
  templateUrl: './reset-Email-page.component.html',
  styleUrls: ['./reset-Email-page.component.scss'],
  standalone: true,
  imports: [CommonModule, RouterLink, ReactiveFormsModule, FormsModule],
})
export class ResetEmailPageComponent implements OnInit {
  registed = false;
  token: any;
  form: FormGroup;
  busy: boolean = false;
  email: string = '';

  constructor(
    private builder: FormBuilder,
    private auths: AuthService,
    private storage: LocalStorageService,
    private toasterService: ToastService,
  ) {
    this.form = this.builder.group({
      email: ['', [Validators.required, Validators.email]],
      oldemail: ['', [Validators.required]],
    });
  }

  private _revealed = false;

  ngOnInit(): void {
    this.auths.getProfile().subscribe((results: any) => {
      this.email = results.emailAddress;
      this.form.patchValue({
        email: '',
        oldemail: results.emailAddress,
      });
      this.form.controls['oldemail'].disable();
    });
  }

  submit() {
    this.busy = true;
    const payload = {
      neweEmail: this.form.value.email,
      email: this.email,
    };
    this.auths.SendEmailChangeRequest(payload).subscribe((x) => {
      if (x) {
        this.registed = true;
        this.busy = false;
      } else {
        this.toasterService.show('the email is already taken.', 'error');
      }
    });
  }
}
